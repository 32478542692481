<template>
  <b-row no-gutters class="venues-list pt-3">
    <b-col v-for="venue in venues" :key="venue.id" cols="12">
      <b-container class="p-0">
        <venue-list-item
          :venue="venue"
          :search="search"
          :display-menu="displayMenu"
          :advertiser-day="advertiserDay"
          :view="view"
        />
      </b-container>
    </b-col>
  </b-row>
</template>
<script>
import VenueListItem from "../components/VenueListItem"
export default {
  components: {
    VenueListItem
  },
  props: {
    venues: {
      type: Array,
      required: true,
      default: function() {
        return []
      }
    },
    advertiserDay: {
      type: Object,
      required: true
    },
    displayMenu: {
      type: Boolean,
      required: false,
      default: true
    },
    view: {
      type: String,
      default: "list"
    },
    search: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.venues-list {
  background: #42464a;
}
</style>
